import React from "react";
import CarouselHero from "wonderbly-components/lib/CarouselHero";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useCountry } from "src/client/js/view/hooks";
import "./HomepageHeroII.scss";

const HomepageHeroII = ({ items }) => {
  const country = useCountry();

  return (
    <div className="container-guttered-on-desktop-up HomepageHeroII__carousel">
      <CarouselHero
        items={items.map(({ ctaLink, ...item }) => ({
          ...item,
          ctaLink: localiseUrlII(country, ctaLink),
        }))}
        slidesClassName="HomepageHeroII__carousel-container"
      />
    </div>
  );
};
HomepageHeroII.propTypes = {
  items: CarouselHero.propTypes.items.isRequired,
};

export default HomepageHeroII;
